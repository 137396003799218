import {
  GoogleAuthProvider,
  type User,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { auth } from "../firebase";
import type {
  AuthContextType,
  AuthProviderProps,
  LoginFunction,
  UserProfile,
} from "../types";

const functions = getFunctions();
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [viewAsUserProfile, setViewAsUserProfile] =
    useState<UserProfile | null>(null);
  const [isAdminMode, setIsAdminMode] = useState(() => {
    return (
      sessionStorage.getItem("isAdminMode") === "true" && userProfile?.isAdmin
    );
  });
  const [loading, setLoading] = useState(true);
  const [profileError, setProfileError] = useState<string | null>(null);
  const [profileLoading, setProfileLoading] = useState(false);

  // Use a ref to track initial load
  const initialLoadComplete = useRef(false);

  useEffect(() => {
    sessionStorage.setItem("isAdminMode", (!!isAdminMode).toString());
  }, [isAdminMode]);

  // Only sync viewAsUserProfile with userProfile on initial load
  useEffect(() => {
    if (!initialLoadComplete.current && userProfile) {
      setViewAsUserProfile(userProfile);
      initialLoadComplete.current = true;
    }
  }, [userProfile]);

  const fetchUserProfile = useCallback(async () => {
    if (!currentUser) {
      return;
    }

    setProfileLoading(true);
    try {
      const userProfileFunction = httpsCallable(functions, "userProfile");
      const result = await userProfileFunction();
      const newProfile = (result.data as { user: UserProfile }).user;
      setUserProfile(newProfile);
      setProfileError(null);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setProfileError("Failed to fetch user profile");
      setUserProfile(null);
    } finally {
      setProfileLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchUserProfile().catch((error) => {
          console.error("Error fetching user profile:", error);
          setProfileError("Failed to fetch user profile");
          setUserProfile(null);
        });
      } else {
        setUserProfile(null);
        setViewAsUserProfile(null);
        setProfileError(null);
        initialLoadComplete.current = false;
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [fetchUserProfile]);

  const refreshUserProfile = useCallback(async () => {
    if (currentUser) {
      await fetchUserProfile();
    }
  }, [currentUser, fetchUserProfile]);

  const login: LoginFunction = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      if (result.user) {
        setCurrentUser(result.user);
        const loginFunction = httpsCallable(functions, "login");
        await loginFunction();
        await fetchUserProfile();
      }
    } catch (error) {
      console.error("Failed to log in:", error);
      setProfileError("Failed to fetch user profile");
      setCurrentUser(null);
      setUserProfile(null);
      setViewAsUserProfile(null);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      const logoutFunction = httpsCallable(functions, "logout");
      await logoutFunction();
      await signOut(auth);
      setProfileError(null);
    } catch (error) {
      console.error("Failed to log out:", error);
      setProfileError("Failed to log out");
    }
    setCurrentUser(null);
    setUserProfile(null);
    setViewAsUserProfile(null);
    setIsAdminMode(false);
    initialLoadComplete.current = false;
  };

  const toggleAdminMode = () => {
    if (userProfile?.isAdmin) {
      setIsAdminMode((prevMode) => !prevMode);
    }
  };

  const value: AuthContextType = {
    currentUser,
    userProfile,
    viewAsUserProfile,
    isAdminMode,
    login,
    logout,
    toggleAdminMode,
    refreshUserProfile,
    profileError,
    profileLoading,
    setViewAsUserProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
