import { ChevronLeft, ChevronRight } from "lucide-react";
import type React from "react";
import { useState } from "react";
import type { ProblemSet } from "./ProblemSetSection";
import ProblemSetSection from "./ProblemSetSection";

// Helper to generate dates relative to now
const getRelativeDate = (dayOffset: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + dayOffset);
  date.setHours(0, 0, 0, 0);
  return date;
};

// Sample data generator with realistic AIME problem sets
const generateDemoProblemSets = (): ProblemSet[] => {
  return [
    {
      id: "aime-1983-target-1",
      title: "Target Practice - AIME 1983-1984 1-8",
      availableDate: getRelativeDate(-1),
      challengeId: "PXNzNd1WAquj1OaRRsw6",
      status: "completed",
      problems: [
        {
          id: "1983-1",
          label: "1",
          userAnswer: "060",
          timeSpent: "15:30",
          isCorrect: true,
          submittedAt: getRelativeDate(-1),
        },
        {
          id: "1984-8",
          label: "8",
          userAnswer: "160",
          timeSpent: "12:45",
          isCorrect: true,
          submittedAt: getRelativeDate(-1),
        },
      ],
    },
    {
      id: "aime-1984-target-1",
      title: "Target Practice - AIME 1983-1984 1-9",
      availableDate: getRelativeDate(0),
      challengeId: "QYMmPe2XBruj2PbSSt7x",
      status: "available",
      problems: [
        {
          id: "1984-1",
          label: "1",
          userAnswer: "093",
          timeSpent: "20:15",
          isCorrect: false,
          submittedAt: new Date(),
        },
        {
          id: "1983-9",
          label: "9",
          userAnswer: null,
          timeSpent: null,
        },
      ],
    },
    {
      id: "aime-1985-target-1",
      title: "Target Practice - AIME 1985-1986 1-8",
      availableDate: getRelativeDate(1),
      status: "locked",
      message: "This problem set will be available tomorrow",
      challengeId: "RZNoPf3YCtuk3QcTTu8y",
    },
    {
      id: "aime-1985-target-2",
      title: "Target Practice - AIME 1985-1986 1-9",
      availableDate: getRelativeDate(2),
      status: "locked",
      message: "Complete previous problem sets to unlock",
      challengeId: "SaNpPg4ZDvwl4RdUUv9z",
    },
  ];
};

const ProblemSetDemo: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [problemSets] = useState(generateDemoProblemSets());

  const handleStartChallenge = (challengeId: string) => {
    console.log(`Starting challenge: ${challengeId}`);
    alert(`Starting challenge ${challengeId}`);
  };

  const handleRequestUnlock = () => {
    console.log("Requesting early access");
    alert("Request for early access sent to instructor");
  };

  const adjustDate = (days: number) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-3xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header */}
          <div className="p-6 border-b border-gray-200">
            <h1 className="text-2xl font-bold text-gray-900">
              Problem Set Component Demo
            </h1>
            <p className="mt-2 text-gray-600">
              Demonstrates the ProblemSetSection component with hover interactions
              and various states
            </p>
          </div>

          {/* Time Control Panel */}
          <div className="bg-gray-50 p-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => adjustDate(-1)}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  type="button"
                >
                  <ChevronLeft className="w-4 h-4 mr-1" />
                  Previous Day
                </button>
                <button
                  onClick={() => adjustDate(1)}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  type="button"
                >
                  Next Day
                  <ChevronRight className="w-4 h-4 ml-1" />
                </button>
              </div>
              <div className="text-sm text-gray-600">
                Current Date: {formatDate(currentDate)}
              </div>
            </div>
          </div>

          {/* Problem Sets */}
          <div className="p-6">
            <div className="space-y-4">
              {problemSets.map((problemSet) => (
                <ProblemSetSection
                  key={problemSet.id}
                  problemSet={problemSet}
                  currentDate={currentDate}
                  onStartChallenge={handleStartChallenge}
                  onRequestUnlock={handleRequestUnlock}
                />
              ))}
            </div>
          </div>

          {/* Usage Instructions */}
          <div className="bg-blue-50 p-6 border-t border-blue-100">
            <h3 className="font-semibold text-blue-900 mb-2">
              Component Features
            </h3>
            <ul className="list-disc list-inside text-sm text-blue-800 space-y-1">
              <li>Hover over problems to see attempt history</li>
              <li>Use date controls to see different problem set states</li>
              <li>Click "Start Challenge" to begin available problem sets</li>
              <li>Request early access for locked problem sets</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemSetDemo;
