import { getFunctions, httpsCallable } from "firebase/functions";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import type { ChallengeData, ChallengeRun, Exam } from "../types";
import ChallengeEditor from "./ChallengeEditor";
import ConfirmModal from "./ConfirmModal";
import CreateExam from "./CreateExam";
import ExamContent from "./ExamContent";
import ExamEditor from "./ExamEditor";
import PhilosophyModal from "./PhilosophyModal";
import TrainingModal from "./TrainingModal";

const ExamComponent: React.FC = () => {
  const { examId, challengeId } = useParams<{
    examId?: string;
    challengeId?: string;
  }>();
  const [exam, setExam] = useState<Exam | null>(null);
  const [challenge, setChallenge] = useState<ChallengeData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [showPhilosophyModal, setShowPhilosophyModal] = useState(false);
  const [showExamContent, setShowExamContent] = useState(false);
  const [challengeRun, setChallengeRun] = useState<ChallengeRun | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { currentUser, userProfile, viewAsUserProfile, isAdminMode } =
    useAuth();
  const navigate = useNavigate();
  const isViewingAsOtherUser =
    isAdminMode && viewAsUserProfile?.email !== userProfile?.email;

  const refreshChallengeState = useCallback(
    async (storedChallengeRun: ChallengeRun) => {
      try {
        const functions = getFunctions();
        const getChallengeDetailsFunction = httpsCallable<
          { challengeId: string },
          { challenge: ChallengeData }
        >(functions, "getChallengeDetails");

        const detailsResult = await getChallengeDetailsFunction({
          challengeId: storedChallengeRun.challenge.id,
        });

        storedChallengeRun.challenge = detailsResult.data.challenge;
        setChallengeRun(storedChallengeRun);
        setShowExamContent(true);
      } catch (err) {
        console.error("Error refreshing challenge state:", err);
        setError("Failed to refresh challenge state. Please try again.");
      }
    },
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (examId === "new") {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const functions = getFunctions();

        if (challengeId) {
          const getChallengeDetails = httpsCallable<
            { challengeId: string },
            { challenge: ChallengeData }
          >(functions, "getChallengeDetails");
          const result = await getChallengeDetails({ challengeId });
          setChallenge(result.data.challenge);
          setError(null);

          if (isAdminMode) {
            if (!isViewingAsOtherUser) {
              setShowExamContent(true);
            }
          } else {
            setShowConfirmModal(true);
          }
        } else if (examId) {
          const getExam = httpsCallable<{ examId: string }, { exam: Exam }>(
            functions,
            "getExam",
          );
          const result = await getExam({ examId });
          setExam(result.data.exam);
          setError(null);

          const storedChallengeRun = localStorage.getItem(
            `challenge_${examId}`,
          );

          if (isAdminMode) {
            if (!isViewingAsOtherUser) {
              setShowExamContent(true);
            }
          } else if (!storedChallengeRun) {
            setShowTrainingModal(true);
          } else {
            await refreshChallengeState(JSON.parse(storedChallengeRun));
          }
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    examId,
    challengeId,
    isAdminMode,
    refreshChallengeState,
    isViewingAsOtherUser,
  ]);

  const startChallenge = async (type: string): Promise<void> => {
    if (!examId || isViewingAsOtherUser) return;

    try {
      const functions = getFunctions();
      const startChallengeFunction = httpsCallable<
        { examId: string; challengeType: string },
        { challengeRun: ChallengeRun }
      >(functions, "startChallenge");
      const result = await startChallengeFunction({
        examId,
        challengeType: type,
      });

      setChallengeRun(result.data.challengeRun);
      localStorage.setItem(
        `challenge_${examId}`,
        JSON.stringify(result.data.challengeRun),
      );

      setShowTrainingModal(false);
      setShowExamContent(true);
    } catch (err) {
      console.error(`Error starting ${type} challenge:`, err);
      setError(`Failed to start ${type} challenge. Please try again.`);
    }
  };

  const handleStartExam = () => {
    if (isViewingAsOtherUser) return;
    setShowTrainingModal(false);
    setShowExamContent(true);
  };

  const handleDirectChallengeStart = async () => {
    if (!challengeId || isViewingAsOtherUser) return;

    try {
      const functions = getFunctions();
      const startChallengeFunction = httpsCallable<
        { challengeId: string },
        { challengeRun: ChallengeRun }
      >(functions, "startChallenge");
      const result = await startChallengeFunction({ challengeId });

      setChallengeRun(result.data.challengeRun);
      setShowExamContent(true);
    } catch (err) {
      console.error("Error starting challenge run:", err);
      setError("Failed to start the challenge. Please try again.");
    }
  };

  const handleFinish = () => {
    if (examId) {
      localStorage.removeItem(`challenge_${examId}`);
    }
    navigate("/");
  };

  if (loading) {
    return <div className="p-6 text-center">Loading data...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">Error: {error}</div>;
  }

  if (examId === "new") {
    return <CreateExam />;
  }

  if (!exam && !challenge) {
    return <div className="p-6 text-center">No data available.</div>;
  }

  // If in admin view-as mode, show disabled state
  if (isViewingAsOtherUser) {
    return (
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4">
          {exam ? exam.title : challenge?.name}
        </h1>
        <div className="text-gray-600">
          Cannot interact with challenges while viewing as{" "}
          {viewAsUserProfile?.name}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">
        {exam ? exam.title : challenge?.name}
      </h1>
      {showExamContent &&
        (isAdminMode ? (
          challengeRun ? (
            <ChallengeEditor challengeRun={challengeRun} />
          ) : exam ? (
            <ExamEditor exam={exam} />
          ) : null
        ) : (
          challengeRun && (
            <ExamContent
              exam={exam}
              challengeRun={challengeRun}
              challengeProblems={challengeRun.challenge.problems}
              onFinish={handleFinish}
            />
          )
        ))}
      {exam && !isAdminMode && (
        <TrainingModal
          exam={exam}
          isOpen={showTrainingModal}
          onClose={() => setShowTrainingModal(false)}
          onTakeExam={handleStartExam}
          onStartChallenge={startChallenge}
          onViewPhilosophy={() => setShowPhilosophyModal(true)}
        />
      )}
      <PhilosophyModal
        isOpen={showPhilosophyModal}
        onClose={() => setShowPhilosophyModal(false)}
      />
      <ConfirmModal
        isOpen={showConfirmModal}
        onConfirm={handleDirectChallengeStart}
        onCancel={() => navigate("/")}
        onClose={() => setShowConfirmModal(false)}
        message={`Are you ready to start the ${challenge?.name} challenge?`}
      />
    </div>
  );
};

export default ExamComponent;
