import type {
  Command,
  DefaultPenCommand,
  DrawCommand,
  Path,
  Point,
} from "../types";
import { Lexer } from "./lexer";

export class Parser {
  private lexer: Lexer;
  private tokens: string[];
  private currentToken: number;

  constructor() {
    this.lexer = new Lexer();
    this.tokens = [];
    this.currentToken = 0;
  }

  public parse(code: string): Command[] {
    this.tokens = this.lexer.tokenize(code);
    this.currentToken = 0;
    const commands: Command[] = [];

    while (this.currentToken < this.tokens.length) {
      if (this.match("defaultpen")) {
        commands.push(this.parseDefaultPen());
      } else if (this.match("draw")) {
        commands.push(this.parseDraw());
      } else {
        this.advance(); // Skip unknown tokens
      }
    }

    return commands;
  }

  private parseDefaultPen(): DefaultPenCommand {
    this.expect("(");
    this.expect("linewidth");
    this.expect("(");
    const width = this.parseNumber();
    this.expect(")");
    this.expect(")");
    this.expect(";");

    return {
      type: "defaultpen",
      params: {
        lineWidth: width,
      },
    };
  }

  private parseDraw(): DrawCommand {
    const points: Point[] = [];
    this.expect("(");

    // First point
    this.expect("(");
    points.push(this.parseCoordinates());

    // Additional points via -- operator
    while (this.match("--")) {
      this.expect("(");
      points.push(this.parseCoordinates());
    }

    this.expect(")");
    this.expect(";");

    return {
      type: "draw",
      params: {
        path: { points },
      },
    };
  }

  private parseCoordinates(): Point {
    const x = this.parseNumber();
    this.expect(",");
    const y = this.parseNumber();
    this.expect(")");
    return { x, y };
  }

  private parseNumber(): number {
    const token = this.tokens[this.currentToken];
    if (!/^\d+\.?\d*$/.test(token)) {
      throw new Error(`Expected number, got ${token}`);
    }
    this.advance();
    return Number.parseFloat(token);
  }

  private match(expected: string): boolean {
    if (this.tokens[this.currentToken] === expected) {
      this.advance();
      return true;
    }
    return false;
  }

  private expect(expected: string): void {
    if (!this.match(expected)) {
      throw new Error(
        `Expected "${expected}", got "${this.tokens[this.currentToken]}"`,
      );
    }
  }

  private advance(): void {
    this.currentToken++;
  }
}
