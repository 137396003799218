import type React from "react";
import { useNavigate } from "react-router-dom";
import type { Exam } from "../types";

interface NonAMCViewProps {
  nonAMCTests: Exam[];
  isAdminMode: boolean;
  isViewingAsOtherUser: boolean;
}

const NonAMCView: React.FC<NonAMCViewProps> = ({
  nonAMCTests,
  isAdminMode,
  isViewingAsOtherUser,
}) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
      {nonAMCTests
        .sort((a, b) => Number(a.year) - Number(b.year))
        .map((test) => (
          <div
            key={test.id}
            className="bg-white rounded-md shadow-sm p-3 flex flex-col justify-between border hover:bg-gray-50 transition-colors"
          >
            <div>
              <h3 className="font-semibold text-sm">{test.competition}</h3>
              <p className="text-gray-500 text-xs">
                {`${test.year} - ${test.name}`}
              </p>
            </div>
            {isAdminMode ? (
              <button
                type="button"
                onClick={() => navigate(`/exam/${test.id}`)}
                className="mt-2 bg-blue-500 text-white py-1 px-2 rounded text-xs hover:bg-blue-600 transition-colors"
                aria-label={`Edit ${test.name}`}
              >
                Edit Test
              </button>
            ) : (
              <button
                type="button"
                onClick={() =>
                  !isViewingAsOtherUser && navigate(`/exam/${test.id}`)
                }
                className={`mt-2 py-1 px-2 rounded text-xs transition-colors ${
                  isViewingAsOtherUser
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
                disabled={isViewingAsOtherUser}
              >
                Take Test
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default NonAMCView;
