import { AlertTriangle, Check, Clipboard } from "lucide-react";
import React, { useState } from "react";

const AIMEAdminConsole = ({ lastAPIResponse, onSimulateResponse }) => {
  const [editableResponse, setEditableResponse] = useState("");
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);

  const handleCopyFromAPI = () => {
    setEditableResponse(JSON.stringify(lastAPIResponse, null, 2));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSimulate = () => {
    try {
      const parsedData = JSON.parse(editableResponse);
      setError("");
      onSimulateResponse(parsedData);
    } catch (err) {
      setError("Invalid JSON format");
    }
  };

  return (
    <div className="mt-8 bg-gray-900 rounded-lg shadow-lg overflow-hidden text-white">
      <div className="p-4 bg-gray-800 border-b border-gray-700">
        <h3 className="text-lg font-semibold flex items-center gap-2">
          AIME Admin Console
        </h3>
      </div>

      <div className="p-4 grid grid-cols-2 gap-4">
        {/* Last API Response */}
        <div className="space-y-2">
          <div className="flex justify-between items-center mb-2">
            <div className="text-sm text-gray-300">Last API Response</div>
            <button
              onClick={handleCopyFromAPI}
              className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-sm flex items-center gap-2"
              type="button"
            >
              {copied ? (
                <Check className="w-4 h-4" />
              ) : (
                <Clipboard className="w-4 h-4" />
              )}
              {copied ? "Copied!" : "Copy to Editor"}
            </button>
          </div>
          <textarea
            value={JSON.stringify(lastAPIResponse, null, 2)}
            readOnly
            className="w-full h-96 p-3 bg-gray-800 text-gray-300 font-mono text-sm rounded border border-gray-700 focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Editable Response */}
        <div className="space-y-2">
          <div className="flex justify-between items-center mb-2">
            <div className="text-sm text-gray-300">Edit Response</div>
            <button
              onClick={handleSimulate}
              className="px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors text-sm"
              type="button"
            >
              Simulate Response
            </button>
          </div>
          <textarea
            value={editableResponse}
            onChange={(e) => setEditableResponse(e.target.value)}
            placeholder="Edit JSON here to simulate API response..."
            className={`w-full h-96 p-3 bg-gray-800 text-gray-300 font-mono text-sm rounded border focus:outline-none ${
              error ? "border-red-500" : "border-gray-700 focus:border-blue-500"
            }`}
          />
          {error && (
            <div className="flex items-center gap-2 text-red-400 text-sm mt-2">
              <AlertTriangle className="w-4 h-4" />
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIMEAdminConsole;
