import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

/**
 * Makes an API call to a Firebase function, handling nested data responses.
 * @param name The name of the Firebase function.
 * @param data (Optional) The data to send to the Firebase function.
 * @returns A promise that resolves to the response data of type R.
 */
async function makeAPICall<T = void, R = any>(
  name: string,
  data?: T,
): Promise<R> {
  const func = httpsCallable<T, any>(functions, name);
  const result = await func(data as T);

  const resData = result.data;

  if (resData && typeof resData === "object" && "data" in resData) {
    console.warn(
      `Warning: Function "${name}" returned data nested under a 'data' property.`,
    );
    return resData.data as R;
  }
  return resData as R;
}

export { makeAPICall };
