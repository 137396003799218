import { ChevronRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import LatexRenderer from "./LatexRenderer";

const KPMTPracticeNote = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8">
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-blue-600">
              Home
            </Link>
            <ChevronRight size={16} className="mx-2" />
            <span>Practice Notes</span>
          </div>

          <article className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100">
            <div className="px-8 py-6">
              <header className="mb-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                  KPMT Practice #1
                </h1>
                <time className="text-sm text-gray-600">November 18, 2024</time>
              </header>

              <div className="prose max-w-none text-gray-700">
                <p>
                  We had a good practice with the whole team present even on a
                  very late notice for the meeting schedule. One of the first
                  things we did was the first AIME challenge (
                  <Link
                    to="/challenge/PXNzNd1WAquj1OaRRsw6"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Target Practice 1-8 for AIME 1998-1999
                  </Link>
                  ) which identified knowledge/familiarity gaps with (1)
                  logarithms and (2) de Moivre's theorem.
                </p>

                <h2 className="text-xl font-bold mt-6 mb-4">
                  Logarithm Review
                </h2>
                <p>
                  This was a great opportunity for me to review some of the
                  material that is taught in Algebra 2/Precalculus about logs,
                  e.g., how to compute <LatexRenderer latex="\\log_{47}(560)" />
                  . We demonstrate the reason that{" "}
                  <LatexRenderer latex="\\log_x(y) = \\frac{\\log(y)}{\\log(x)}" />
                  and we have freedom to let the base of those logs be any value
                  as long as it is internally consistent.
                </p>

                <h2 className="text-xl font-bold mt-6 mb-4">
                  De Moivre's Theorem
                </h2>
                <p>
                  Then we turned our attention to de Moivre's theorem, which in
                  a nutshell states that{" "}
                  <LatexRenderer latex="e^{i\\theta} = \\cos(\\theta) + i\\sin(\\theta)" />
                  aka <LatexRenderer latex="\\text{cis}(\\theta)" />. In
                  particular the theorem states that{" "}
                  <LatexRenderer latex="\\text{cis}(n\\theta) = (\\text{cis}(\\theta))^n" />{" "}
                  which is immediately obvious if you have established that{" "}
                  <LatexRenderer latex="e^{i\\theta}" /> is equal to this value.
                </p>

                <p>
                  We arrived here by assuming that most of the functions the
                  kids work with at this time have a Taylor series, or more
                  specifically a Maclaurin series. If there is a Taylor series
                  for exp and the derivative of exp is itself, then we can infer
                  that the Taylor series has to be:
                </p>

                <div className="my-4 flex justify-center">
                  <LatexRenderer latex="e^x = 1 + x + \\frac{x^2}{2!} + \\frac{x^3}{3!} + \\cdots" />
                </div>

                <p>
                  Likewise if sin and cos have their own Taylor series with{" "}
                  <LatexRenderer latex="\\frac{d}{dx}\\sin(x) = \\cos(x)" />
                  and{" "}
                  <LatexRenderer latex="\\frac{d}{dx}\\cos(x) = -\\sin(x)" />{" "}
                  then their respective series are:
                </p>

                <div className="my-4 flex flex-col items-center space-y-2">
                  <LatexRenderer latex="\\sin(x) = x - \\frac{x^3}{3!} + \\frac{x^5}{5!} - \\cdots" />
                  <LatexRenderer latex="\\cos(x) = 1 - \\frac{x^2}{2!} + \\frac{x^4}{4!} - \\cdots" />
                </div>

                <p>From this we can find values like:</p>
                <ul className="list-disc pl-6 my-4">
                  <li>
                    <LatexRenderer latex="e^1 = 1 + 1 + \\frac{1}{2} + \\frac{1}{6} + \\frac{1}{24} + \\cdots" />
                  </li>
                  <li>
                    <LatexRenderer latex="\\frac{1}{e} = e^{-1} = 1 - 1 + \\frac{1}{2} - \\frac{1}{6} + \\frac{1}{24} - \\cdots" />
                    (which is a very quickly converging sequence)
                  </li>
                  <li>
                    We could also find <LatexRenderer latex="\\sin(1)" /> or{" "}
                    <LatexRenderer latex="\\cos(1)" /> in radians although we
                    didn't actually explore this
                  </li>
                </ul>

                <p>
                  More importantly, we can establish De Moivre's theorem and
                  hence:
                </p>
                <ul className="list-disc pl-6 my-4">
                  <li>
                    The roots of <LatexRenderer latex="1 + x + x^2" /> would
                    have to be{" "}
                    <LatexRenderer latex="\\text{cis}(\\frac{2\\pi}{3})" />
                    and <LatexRenderer latex="\\text{cis}(\\frac{4\\pi}{3})" />
                  </li>
                  <li>
                    The roots of <LatexRenderer latex="1 + x^3 + x^6" /> would
                    have to be a certain subset of the 9th roots of unity
                  </li>
                </ul>

                <h2 className="text-xl font-bold mt-6 mb-4">KPMT Practice</h2>
                <p>
                  Finally we practiced a sample algebra unit from last year's
                  KPMT test and reviewed some of the problems within the
                  remainder of the hour.
                </p>

                <h2 className="text-xl font-bold mt-6 mb-4">Looking Forward</h2>
                <p>
                  Looking forward to the coming weeks! Some of the meetings may
                  be preempted for USAMTS review where students including our
                  group and other strong students in the area can discuss
                  olympiad style writeups motivated by the problems for the
                  USAMTS. I hope this practice alternating with the
                  computational practice will give the students a well-rounded
                  experience.
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default KPMTPracticeNote;
