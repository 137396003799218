import { useCallback, useEffect, useState } from "react";
import { makeAPICall } from "../api/apiClient";
import type { ChallengeData, GradedChallenge } from "../types";

interface AIMEChallenge extends ChallengeData {
  answers?: Record<string, string>; // Map of problem labels to answers
  availableDate: Date;
}

interface AIMEUserProgress {
  challengeId: string;
  problemResponses: {
    label: string;
    answer?: string;
    timeSpent?: string;
    isCorrect?: boolean;
    submittedAt?: string;
  }[];
}

export interface AIMEData {
  challenges: AIMEChallenge[];
  userProgress: AIMEUserProgress[];
  gradedChallenges: GradedChallenge[];
}

export const useAIMEData = () => {
  const [aimeData, setAIMEData] = useState<AIMEData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAIMEData = useCallback(async () => {
    try {
      setAIMEData(await makeAPICall<void, AIMEData>("getAIMEData"));
      setError(null);
    } catch (err) {
      console.error("Error fetching AIME data:", err);
      setError("Failed to load AIME data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAIMEData();
  }, [fetchAIMEData]);

  return {
    aimeData,
    loading,
    error,
    refreshData: fetchAIMEData,
  };
};
