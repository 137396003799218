import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Exam, TestProgress } from "../types";

interface UseTestsReturn {
  tests: Exam[];
  loading: boolean;
  error: string | null;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectedCompetition: string;
  setSelectedCompetition: (competition: string) => void;
  filteredTests: Exam[];
  userProgress: Record<string, TestProgress>;
}

const useTests = (): UseTestsReturn => {
  const [tests, setTests] = useState<Exam[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCompetition, setSelectedCompetition] = useState<string>("");
  const [userProgress, setUserProgress] = useState<
    Record<string, TestProgress>
  >({});

  // Memoize the functions instance to prevent unnecessary re-initializations
  const functions = useMemo(() => getFunctions(), []);

  // Fetch tests from the backend
  const fetchTests = useCallback(async () => {
    try {
      setLoading(true);
      const listExams = httpsCallable(functions, "listExams");
      const result = await listExams();
      const data = result.data as { tests: Exam[] };
      setTests(data.tests || []);
      setError(null);
    } catch (err) {
      console.error("Error fetching tests:", err);
      setError("Failed to load tests. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [functions]);

  // Fetch user progress from the backend
  const fetchUserProgress = useCallback(async () => {
    try {
      const getUserProgressFunction = httpsCallable<
        void,
        { userProgress: Record<string, TestProgress> }
      >(functions, "getUserProgress");

      const progressResult = await getUserProgressFunction();
      setUserProgress(progressResult.data.userProgress || {});
    } catch (err) {
      console.error("Error fetching user progress:", err);
      // Not setting error state to avoid blocking main functionality
    }
  }, [functions]);

  // Use useEffect to call fetch functions when component mounts
  useEffect(() => {
    fetchTests();
    fetchUserProgress();
  }, [fetchTests, fetchUserProgress]);

  // Filter tests based on search term and selected competition
  const filteredTests = useMemo(() => {
    return tests.filter((test) => {
      const matchesSearch = test.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesCompetition =
        selectedCompetition === "" || test.competition === selectedCompetition;
      return matchesSearch && matchesCompetition;
    });
  }, [tests, searchTerm, selectedCompetition]);

  return {
    tests,
    loading,
    error,
    searchTerm,
    setSearchTerm,
    selectedCompetition,
    setSelectedCompetition,
    filteredTests,
    userProgress,
  };
};

export default useTests;
