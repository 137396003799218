import type { Command, DefaultPenCommand, DrawCommand, Point } from "../types";

export class Renderer {
  private ctx: CanvasRenderingContext2D;

  constructor(canvas: HTMLCanvasElement) {
    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Failed to get 2D context");
    }
    this.ctx = context;
  }

  public execute(commands: Command[]): void {
    for (const cmd of commands) {
      const type = cmd.type;
      switch (type) {
        case "defaultpen":
          this.setDefaultPen(cmd);
          break;
        case "draw":
          this.drawPath(cmd);
          break;
        default:
          console.warn(`Unknown command type: ${type}`);
      }
    }
  }

  private setDefaultPen(cmd: DefaultPenCommand): void {
    this.ctx.lineWidth = cmd.params.lineWidth;
  }

  private drawPath(cmd: DrawCommand): void {
    const { points } = cmd.params.path;
    if (points.length < 2) {
      return;
    }

    this.ctx.beginPath();
    this.ctx.moveTo(points[0].x, points[0].y);

    for (let i = 1; i < points.length; i++) {
      this.ctx.lineTo(points[i].x, points[i].y);
    }

    this.ctx.stroke();
  }
}
