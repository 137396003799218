import { format } from "date-fns";
import type React from "react";
import { useEffect, useState } from "react";
import type {
  Action,
  ChallengeRun,
  ProblemAnalysis,
  ProblemResponse,
} from "../types";

// Define EnhancedProblem here since it's not exported from "../types"
export interface EnhancedProblem {
  problemId: string;
  label: string;
  correctAnswer?: string;
  statement?: string;
}

// Define EnhancedChallengeRun using EnhancedProblem
export interface EnhancedChallengeRun
  extends Omit<ChallengeRun, "responses" | "challenge"> {
  userName?: string;
  challenge: {
    id: string;
    name: string;
    type: string;
    examType: string;
    problems: EnhancedProblem[];
  };
  responses: Record<string, ProblemResponse>;
}

interface ChallengeScoringSummaryProps {
  challengeRun: EnhancedChallengeRun;
  onClose: () => void;
  onSubmit: (analysis: ProblemAnalysis[]) => void;
}

const ChallengeScoringSummary: React.FC<ChallengeScoringSummaryProps> = ({
  challengeRun,
  onClose,
  onSubmit,
}) => {
  const [problemAnalysis, setProblemAnalysis] = useState<ProblemAnalysis[]>([]);

  useEffect(() => {
    const initialAnalysis = challengeRun.challenge.problems.map((problem) => ({
      problemId: problem.problemId,
      status: determineInitialStatus(
        challengeRun.responses[problem.label],
        problem,
      ),
    }));
    setProblemAnalysis(initialAnalysis);
  }, [challengeRun]);

  const determineInitialStatus = (
    problemResponse: ProblemResponse | undefined,
    problem: EnhancedProblem,
  ): "mastered" | "suboptimal" | "needs_review" => {
    if (!problemResponse) return "needs_review";

    const submissions = problemResponse.actions.filter(
      (action) => action.type === "submitAnswer",
    );

    if (submissions.length === 0) return "needs_review";

    const firstSubmission = submissions[0];

    if (
      submissions.length === 1 &&
      firstSubmission.data?.timeSpent !== undefined &&
      firstSubmission.data.timeSpent <= 60000 &&
      firstSubmission.data.answer === problem.correctAnswer
    ) {
      return "mastered";
    }

    if (
      submissions.some(
        (action) => action.data?.answer === problem.correctAnswer,
      )
    ) {
      return "suboptimal";
    }

    return "needs_review";
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "mastered":
        return "bg-green-100";
      case "suboptimal":
        return "bg-yellow-100";
      case "needs_review":
        return "bg-red-100";
      default:
        return "bg-gray-100";
    }
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
  };

  return (
    <div className="bg-white rounded-lg w-full max-w-4xl mx-auto my-8 p-6 border border-gray-300 shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Grade Challenge</h2>
      <p className="text-sm mb-2">Challenge Run ID: {challengeRun.id}</p>
      <p className="text-sm mb-2">
        User: {challengeRun.userName || challengeRun.userId}
      </p>
      <p className="text-sm mb-2">Challenge: {challengeRun.challenge.name}</p>
      <p className="text-sm mb-4">
        Started At: {formatDate(challengeRun.startedAt)}
      </p>
      <div className="space-y-6 mb-6 max-h-[60vh] overflow-y-auto">
        {challengeRun.challenge.problems.map((problem) => {
          const problemResponse = challengeRun.responses[problem.label];
          const analysis = problemAnalysis.find(
            (p) => p.problemId === problem.problemId,
          ) || {
            status: "needs_review",
          };
          const statusColor = getStatusColor(analysis.status);

          return (
            <div
              key={problem.problemId}
              className={`p-4 border rounded ${statusColor}`}
            >
              <h4 className="font-semibold">Problem {problem.label}</h4>

              {problem.statement && (
                <p className="text-sm mt-1">Statement: {problem.statement}</p>
              )}

              {problem.correctAnswer && (
                <p className="text-sm mt-1">
                  Correct Answer: {problem.correctAnswer}
                </p>
              )}

              <div className="mt-2">
                <h5 className="font-medium text-sm">Actions:</h5>
                {problemResponse?.actions ? (
                  <table className="w-full text-sm">
                    <thead>
                      <tr>
                        <th className="text-left pr-4">Timestamp</th>
                        <th className="text-left pr-4">Action</th>
                        <th className="text-left">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {problemResponse.actions.map((action, index) => (
                        <tr key={`${action.timestamp}-${index}`}>
                          <td className="pr-4 font-mono">
                            {formatDate(action.timestamp)}
                          </td>
                          <td className="pr-4">{action.type}</td>
                          <td>
                            {action.type === "submitAnswer"
                              ? `Answer: "${action.data?.answer}" (Time spent: ${(
                                  Number(action.data?.timeSpent ?? 0) / 1000
                                ).toFixed(1)}s)`
                              : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-sm">No actions recorded</p>
                )}
              </div>

              <div className="mt-2">
                <label className="block text-sm">
                  Status:
                  <select
                    className="ml-2 border rounded p-1 text-sm"
                    value={analysis.status}
                    onChange={(e) => {
                      setProblemAnalysis((prev) =>
                        prev.map((p) =>
                          p.problemId === problem.problemId
                            ? {
                                ...p,
                                status: e.target.value as
                                  | "mastered"
                                  | "suboptimal"
                                  | "needs_review",
                              }
                            : p,
                        ),
                      );
                    }}
                  >
                    <option value="mastered">Mastered</option>
                    <option value="suboptimal">Suboptimal</option>
                    <option value="needs_review">Needs Review</option>
                  </select>
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end space-x-2">
        <button
          onClick={onClose}
          className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 text-sm"
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => onSubmit(problemAnalysis)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 text-sm"
          type="button"
        >
          Submit Grade
        </button>
      </div>
    </div>
  );
};

export default ChallengeScoringSummary;
