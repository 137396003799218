import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "../utils/uuid";

const AlertDialog: React.FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
}> = ({ open, onOpenChange, children }) => {
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }

    const handleCancel = (event: Event) => {
      event.preventDefault();
      onOpenChange(false);
    };

    const dialog = dialogRef.current;
    dialog?.addEventListener("cancel", handleCancel);

    return () => {
      dialog?.removeEventListener("cancel", handleCancel);
    };
  }, [open, onOpenChange]);

  return (
    <dialog ref={dialogRef} className="rounded-lg p-6 max-w-sm w-full">
      {children}
    </dialog>
  );
};

const AlertDialogContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div>{children}</div>;

const AlertDialogHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="mb-4">{children}</div>;

const AlertDialogTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <h3 className="text-lg font-semibold">{children}</h3>;

const AlertDialogDescription: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <p className="text-sm text-gray-500">{children}</p>;

const AlertDialogFooter: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="flex justify-end">{children}</div>;

const AlertDialogAction: React.FC<{
  onClick: () => void;
  children: React.ReactNode;
}> = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
    type="button"
  >
    {children}
  </button>
);

interface Ball {
  id: string;
  color: string;
}

interface EmptySlot {
  id: string;
  empty: true;
}

type Slot = Ball | EmptySlot;

interface Tube {
  id: string;
  slots: Slot[];
}

interface GameState {
  tubes: Tube[];
}

interface HistoryNode {
  id: string;
  state: GameState;
  parent: HistoryNode | null;
  children: HistoryNode[];
}

// Helper function to lighten colors
function lightenColor(hex: string, percent: number) {
  const num = Number.parseInt(hex.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return `rgb(${Math.min(R, 255)}, ${Math.min(G, 255)}, ${Math.min(B, 255)})`;
}

const generateGlossyColors = (count: number): string[] => {
  const baseColors = [
    "#FF0000",
    "#0000FF",
    "#00FF00",
    "#FFFF00",
    "#800080",
    "#FFA500",
    "#00FFFF",
    "#FF00FF",
    "#00FF80",
    "#FFC0CB",
    "#008080",
    "#A52A2A",
    "#000000",
    "#808080",
    "#800000",
    "#808000",
    "#000080",
    "#C0C0C0",
    "#FFD700",
    "#ADFF2F",
  ];
  return baseColors.slice(0, count).map(
    (color) => `
      radial-gradient(circle at 30% 30%, 
        ${lightenColor(color, 30)}, 
        ${color}
      )
    `,
  );
};

// Type Guard Functions
function isEmptySlot(slot: Slot): slot is EmptySlot {
  return "empty" in slot;
}

function isBall(slot: Slot): slot is Ball {
  return "color" in slot;
}

const BallSortingPuzzle: React.FC = () => {
  const [historyRoot, setHistoryRoot] = useState<HistoryNode | null>(null);
  const [currentNode, setCurrentNode] = useState<HistoryNode | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [numTubes, setNumTubes] = useState(6);
  const [tubeCapacity, setTubeCapacity] = useState(4);
  const [numColors, setNumColors] = useState(4);
  const [isEditing, setIsEditing] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedBallForSwap, setSelectedBallForSwap] = useState<{
    tubeId: string;
    slotIndex: number;
  } | null>(null);
  const [selectedBall, setSelectedBall] = useState<{
    tubeId: string;
    slotIndex: number;
  } | null>(null);
  const [colorPalette, setColorPalette] = useState<string[]>([]);

  const COLORS = useMemo(() => generateGlossyColors(numColors), [numColors]);

  const initializeGame = useCallback(() => {
    const totalBalls = numColors * tubeCapacity;
    const filledTubesCount = Math.ceil(totalBalls / tubeCapacity);

    if (numTubes < filledTubesCount) {
      setAlertMessage(
        "Not enough tubes to hold all the balls. Increase the number of tubes.",
      );
      setIsAlertOpen(true);
      return;
    }

    const initialColors = generateGlossyColors(numColors);
    setColorPalette(initialColors);

    const balls = initialColors.flatMap((color) =>
      Array(tubeCapacity)
        .fill(null)
        .map(() => ({ id: uuidv4(), color })),
    );
    const shuffledBalls = balls.sort(() => Math.random() - 0.5);

    const initialTubes: Tube[] = [];
    let ballIndex = 0;
    for (let i = 0; i < numTubes; i++) {
      const slots: Slot[] = [];
      for (let j = 0; j < tubeCapacity; j++) {
        if (ballIndex < balls.length) {
          slots.push(shuffledBalls[ballIndex]);
          ballIndex++;
        } else {
          slots.push({ id: uuidv4(), empty: true });
        }
      }
      initialTubes.push({ id: uuidv4(), slots });
    }

    const initialState: GameState = {
      tubes: initialTubes,
    };
    const initialNode: HistoryNode = {
      id: uuidv4(),
      state: initialState,
      parent: null,
      children: [],
    };
    setHistoryRoot(initialNode);
    setCurrentNode(initialNode);
    setIsEditing(true);
    setIsPlaying(false);
    setSelectedBallForSwap(null);
    setSelectedBall(null);
  }, [numTubes, tubeCapacity, numColors]);

  useEffect(() => {
    initializeGame();
  }, [initializeGame]);

  const handleTubeClick = (tubeId: string) => {
    if (isEditing) {
      // Do nothing on tube click in edit mode
    } else {
      handleTubeClickInPlayMode(tubeId);
    }
  };

  const handleTubeClickInPlayMode = (tubeId: string) => {
    if (!currentNode) return;

    if (selectedBall) {
      if (canMoveSelectedBallToTube(selectedBall, tubeId)) {
        const newTubes = moveSelectedBallToTube(selectedBall, tubeId);
        const newState: GameState = { tubes: newTubes };
        updateGameHistory(newState);
        setSelectedBall(null);
        if (checkWin(newTubes)) {
          setAlertMessage("Congratulations! You've solved the puzzle!");
          setIsAlertOpen(true);
        }
      } else {
        setAlertMessage(
          "Invalid move! The destination tube is full or the colors don't match.",
        );
        setIsAlertOpen(true);
      }
    } else {
      // No ball selected, do nothing
    }
  };

  const swapBalls = (
    fromTubeId: string,
    fromSlotIndex: number,
    toTubeId: string,
    toSlotIndex: number,
  ) => {
    if (!currentNode) return;

    const newTubes = currentNode.state.tubes.map((tube) => ({
      ...tube,
      slots: [...tube.slots],
    }));

    const fromTube = newTubes.find((t) => t.id === fromTubeId);
    const toTube = newTubes.find((t) => t.id === toTubeId);

    if (!fromTube || !toTube) return;

    // Swap the slots
    const temp = fromTube.slots[fromSlotIndex];
    fromTube.slots[fromSlotIndex] = toTube.slots[toSlotIndex];
    toTube.slots[toSlotIndex] = temp;

    const newState: GameState = { tubes: newTubes };
    updateGameHistory(newState);
  };

  const canMoveSelectedBallToTube = (
    selectedBall: { tubeId: string; slotIndex: number },
    toTubeId: string,
  ): boolean => {
    if (!currentNode) return false;
    const { tubes } = currentNode.state;
    const fromTube = tubes.find((t) => t.id === selectedBall.tubeId);
    const toTube = tubes.find((t) => t.id === toTubeId);
    if (!fromTube || !toTube) return false;

    const ball = fromTube.slots[selectedBall.slotIndex];
    if (!isBall(ball)) return false;

    // Destination tube must have space
    if (toTube.slots.every(isBall)) return false;

    // Get the top ball in the destination tube
    const toTopBallIndex = toTube.slots.findIndex(isBall);
    if (toTopBallIndex === -1) {
      // Destination tube is empty, move is allowed
      return true;
    }
    const toTopBall = toTube.slots[toTopBallIndex];
    if (!isBall(toTopBall)) return false;
    // Colors must match
    return toTopBall.color === ball.color;
  };

  const moveSelectedBallToTube = (
    selectedBall: { tubeId: string; slotIndex: number },
    toTubeId: string,
  ): Tube[] => {
    if (!currentNode) return [];
    const newTubes = currentNode.state.tubes.map((tube) => ({
      ...tube,
      slots: [...tube.slots],
    }));

    const fromTube = newTubes.find((t) => t.id === selectedBall.tubeId);
    const toTube = newTubes.find((t) => t.id === toTubeId);

    if (!fromTube || !toTube) return newTubes;

    const ball = fromTube.slots[selectedBall.slotIndex];
    if (!isBall(ball)) return newTubes;

    // Remove the ball from the fromTube
    fromTube.slots[selectedBall.slotIndex] = { id: uuidv4(), empty: true };

    // Find the index to place the ball in toTube
    const toEmptyIndex = toTube.slots.slice().reverse().findIndex(isEmptySlot);
    const toIndex =
      toEmptyIndex === -1 ? -1 : toTube.slots.length - 1 - toEmptyIndex;

    if (toIndex === -1) return newTubes; // No space in toTube

    toTube.slots[toIndex] = ball;

    return newTubes;
  };

  const updateGameHistory = (newState: GameState) => {
    const newNode: HistoryNode = {
      id: uuidv4(),
      state: newState,
      parent: currentNode,
      children: [],
    };
    if (currentNode) {
      currentNode.children.push(newNode);
    }
    setCurrentNode(newNode);
  };

  const undo = () => {
    if (currentNode?.parent) {
      setCurrentNode(currentNode.parent);
    }
  };

  const redo = (childIndex = 0) => {
    if (currentNode && currentNode.children.length > childIndex) {
      setCurrentNode(currentNode.children[childIndex]);
    }
  };

  const checkWin = useCallback(
    (tubes: Tube[]) => {
      return tubes.every((tube) => {
        const balls = tube.slots.filter(isBall);
        return (
          balls.length === 0 ||
          (balls.length === tubeCapacity &&
            balls.every((ball) => ball.color === balls[0].color))
        );
      });
    },
    [tubeCapacity],
  );

  const updateColor = (oldColor: string, newColor: string) => {
    if (!currentNode) return;

    const newTubes = currentNode.state.tubes.map((tube) => ({
      ...tube,
      slots: tube.slots.map((slot) => {
        if (isBall(slot) && slot.color === oldColor) {
          return { ...slot, color: newColor };
        }
        return slot;
      }),
    }));

    const newState: GameState = { tubes: newTubes };
    updateGameHistory(newState);

    setColorPalette((prev) =>
      prev.map((color) => (color === oldColor ? newColor : color)),
    );
  };

  const exportPuzzleState = () => {
    if (!historyRoot || !currentNode) return;

    const exportData = {
      initialState: historyRoot.state,
      currentState: currentNode.state,
      moves: [],
    };

    let node = currentNode;
    while (node.parent) {
      const move = findMove(node.parent.state, node.state);
      if (move) {
        exportData.moves.unshift(move);
      }
      node = node.parent;
    }

    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "ball-sorting-puzzle-state.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  const findMove = (prevState: GameState, currentState: GameState) => {
    // Implement logic to find the move between states
    return null; // Placeholder
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Ball Sorting Puzzle</h1>

      {/* Configuration Panel */}
      {isEditing && (
        <div className="mb-4">
          <label className="block mb-2">
            Number of Tubes (3 to 20):
            <input
              type="number"
              min="3"
              max="20"
              value={numTubes}
              onChange={(e) =>
                setNumTubes(Math.min(Math.max(3, Number(e.target.value)), 20))
              }
              disabled={!isEditing}
              className="ml-2 border p-1 rounded"
            />
          </label>
          <label className="block mb-2">
            Tube Capacity (1 to 8):
            <input
              type="number"
              min="1"
              max="8"
              value={tubeCapacity}
              onChange={(e) =>
                setTubeCapacity(
                  Math.min(Math.max(1, Number(e.target.value)), 8),
                )
              }
              disabled={!isEditing}
              className="ml-2 border p-1 rounded"
            />
          </label>
          <label className="block mb-2">
            Number of Colors (1 to 20):
            <input
              type="number"
              min="1"
              max="20"
              value={numColors}
              onChange={(e) =>
                setNumColors(Math.min(Math.max(1, Number(e.target.value)), 20))
              }
              disabled={!isEditing}
              className="ml-2 border p-1 rounded"
            />
          </label>
        </div>
      )}

      {/* Color Palette */}
      {isEditing && (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-2">Color Palette</h2>
          <div className="flex flex-wrap gap-2">
            {colorPalette.map((color) => (
              <div key={color} className="flex items-center">
                <input
                  type="color"
                  value={color}
                  onChange={(e) => updateColor(color, e.target.value)}
                  className="w-8 h-8 mr-1"
                />
                <span>{color}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Tubes Display */}
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        {currentNode?.state.tubes.map((tube) => (
          <button
            key={tube.id}
            type="button"
            className={`w-16 h-64 border-2 border-gray-300 flex flex-col-reverse items-center p-2 cursor-pointer ${
              selectedBall && selectedBall.tubeId === tube.id
                ? "border-black border-4"
                : ""
            }`}
            onClick={() => handleTubeClick(tube.id)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleTubeClick(tube.id);
              }
            }}
            style={{ background: "transparent", padding: 0 }}
          >
            {tube.slots
              .slice()
              .reverse()
              .map((slot, index) =>
                isBall(slot) ? (
                  <button
                    key={slot.id}
                    type="button"
                    className={`w-12 h-12 rounded-full mb-1 ${
                      selectedBall &&
                      selectedBall.tubeId === tube.id &&
                      selectedBall.slotIndex === tube.slots.length - 1 - index
                        ? "border-4 border-black"
                        : ""
                    }`}
                    style={{
                      background: slot.color,
                      padding: 0,
                      border: "none",
                    }}
                    draggable={isEditing}
                    onDragStart={(e) => {
                      if (isEditing) {
                        e.dataTransfer.setData(
                          "application/json",
                          JSON.stringify({
                            tubeId: tube.id,
                            slotIndex: tube.slots.length - 1 - index,
                          }),
                        );
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isEditing) {
                        if (selectedBallForSwap) {
                          swapBalls(
                            selectedBallForSwap.tubeId,
                            selectedBallForSwap.slotIndex,
                            tube.id,
                            tube.slots.length - 1 - index,
                          );
                          setSelectedBallForSwap(null);
                        } else {
                          setSelectedBallForSwap({
                            tubeId: tube.id,
                            slotIndex: tube.slots.length - 1 - index,
                          });
                        }
                      } else {
                        setSelectedBall({
                          tubeId: tube.id,
                          slotIndex: tube.slots.length - 1 - index,
                        });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        if (isEditing) {
                          // Same logic as onClick
                        } else {
                          setSelectedBall({
                            tubeId: tube.id,
                            slotIndex: tube.slots.length - 1 - index,
                          });
                        }
                      }
                    }}
                  />
                ) : (
                  <div
                    key={slot.id}
                    className="w-12 h-12 mb-1"
                    style={{ backgroundColor: "transparent" }}
                  />
                ),
              )}
          </button>
        ))}
      </div>

      {/* Control Buttons */}
      <div className="flex space-x-2">
        {isEditing && (
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            onClick={() => {
              setIsEditing(false);
              setIsPlaying(true);
            }}
            type="button"
          >
            Start Game
          </button>
        )}
        {isPlaying && (
          <>
            <button
              className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
              onClick={() => {
                setIsEditing(true);
                setIsPlaying(false);
              }}
              type="button"
            >
              Edit Setup
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
              onClick={undo}
              type="button"
            >
              Undo
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
              onClick={() => redo()}
              type="button"
            >
              Redo
            </button>
          </>
        )}
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          onClick={initializeGame}
          type="button"
        >
          New Puzzle
        </button>
        <button
          className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition-colors"
          onClick={exportPuzzleState}
          type="button"
        >
          Export Puzzle State
        </button>
      </div>

      {/* Alert Dialog */}
      <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Game Alert</AlertDialogTitle>
            <AlertDialogDescription>{alertMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setIsAlertOpen(false)}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default BallSortingPuzzle;
