import { CalendarDays, ChevronRight, Clock, Lock } from "lucide-react";
import type React from "react";
import { useState } from "react";
import type { ProblemAttempt } from "./HoverCard";
import HoverCard from "./HoverCard";

export interface Problem {
  id: string;
  label: string;
  userAnswer: string | null;
  timeSpent: string | null;
  isCorrect?: boolean;
  submittedAt?: Date;
}

export interface ProblemSet {
  id: string;
  title: string;
  availableDate: Date;
  problems?: Problem[];
  challengeId?: string;
  status?: "locked" | "available" | "completed";
  message?: string;
}

export interface ProblemSetSectionProps {
  problemSet: ProblemSet;
  currentDate: Date;
  onStartChallenge: (challengeId: string) => void;
  onRequestUnlock?: () => void;
  className?: string;
}

const ProblemSetSection: React.FC<ProblemSetSectionProps> = ({
  problemSet,
  currentDate,
  onStartChallenge,
  onRequestUnlock,
  className = ""
}) => {
  const [hoveredProblem, setHoveredProblem] = useState<string | null>(null);

  const isAvailable = currentDate >= problemSet.availableDate;
  const isLocked = problemSet.status === "locked" || !isAvailable;
  const dateStr = problemSet.availableDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric"
  });

  const getAttemptsForProblem = (problemId: string): ProblemAttempt[] => {
    const problem = problemSet.problems?.find(p => p.id === problemId);
    if (!problem || !problem.submittedAt) return [];

    return [{
      label: problem.label,
      answer: problem.userAnswer,
      isCorrect: problem.isCorrect || false,
      submittedAt: problem.submittedAt.toISOString(),
      timeSpent: problem.timeSpent || undefined
    }];
  };

  if (isLocked) {
    return (
      <div className={`bg-gray-50 rounded-lg border border-gray-200 shadow ${className}`}>
        <div className="p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="font-semibold text-gray-600">{problemSet.title}</div>
            <Lock className="w-5 h-5 text-gray-400" />
          </div>
          <div className="text-sm text-gray-500">
            <div className="flex items-center gap-2">
              <CalendarDays className="w-4 h-4" />
              <span>Assigned for {dateStr}</span>
            </div>
          </div>
          <div className="mt-3 flex items-start space-x-2 text-sm">
            <div className="text-gray-500">
              {problemSet.message || "This problem set is currently locked."}
            </div>
            {onRequestUnlock && (
              <button
                onClick={onRequestUnlock}
                className="flex items-center gap-1 text-blue-600 hover:text-blue-700 font-medium"
                type="button"
              >
                Request early access
                <ChevronRight className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-white rounded-lg border border-gray-200 shadow ${className}`}>
      <div className="p-4">
        <div className="flex justify-between items-center mb-3">
          <div>
            <h3 className="font-semibold text-gray-900">{problemSet.title}</h3>
            <div className="text-sm text-gray-500 mt-1">
              <div className="flex items-center gap-2">
                <CalendarDays className="w-4 h-4" />
                <span>Assigned for {dateStr}</span>
              </div>
            </div>
          </div>
          {problemSet.challengeId && (
            <button
              onClick={() => onStartChallenge(problemSet.challengeId!)}
              className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm"
              type="button"
            >
              Start Challenge
            </button>
          )}
        </div>

        {problemSet.problems && (
          <div className="space-y-2">
            {problemSet.problems.map((problem) => (
              <div
                key={problem.id}
                className="relative flex items-center justify-between bg-gray-50 p-3 rounded hover:bg-gray-100 transition-colors"
                onMouseEnter={() => setHoveredProblem(problem.id)}
                onMouseLeave={() => setHoveredProblem(null)}
              >
                <span className="text-gray-700">
                  Problem {problem.label}
                </span>
                {problem.submittedAt && (
                  <div className="flex items-center space-x-3">
                    {problem.timeSpent && (
                      <div className="flex items-center space-x-1 text-gray-600">
                        <Clock className="w-4 h-4" />
                        <span>{problem.timeSpent}</span>
                      </div>
                    )}
                    {hoveredProblem === problem.id && (
                      <HoverCard
                        title="Previous Attempts"
                        attempts={getAttemptsForProblem(problem.id)}
                        position="top"
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProblemSetSection;
