import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import type { HeaderProps, UserProfile } from "../types";

const ViewAsDropdown: React.FC<{
  viewAsUserProfile: UserProfile | null;
  userProfile: UserProfile | null;
  setViewAsUserProfile: (profile: UserProfile | null) => void;
}> = ({ viewAsUserProfile, userProfile, setViewAsUserProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleReturn = () => {
    if (userProfile) {
      setViewAsUserProfile(userProfile);
      setIsOpen(false);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-3 py-1 text-white hover:bg-red-800 rounded-md text-sm flex items-center space-x-1"
        type="button"
      >
        <span>Viewing As: {viewAsUserProfile?.name}</span>
        <svg
          className={`w-4 h-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <title>Toggle dropdown menu</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
              Currently viewing as:
              <div className="font-medium">{viewAsUserProfile?.name}</div>
            </div>
            <button
              onClick={handleReturn}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              type="button"
            >
              Return to {userProfile?.name}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Header: React.FC<HeaderProps> = ({
  notifications,
  notificationsError,
  markNotificationAsRead,
  NotificationBell,
  UserMenu,
}) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const isLabsPath = location.pathname.startsWith("/labs");

  const {
    currentUser,
    userProfile,
    viewAsUserProfile,
    setViewAsUserProfile,
    login,
    logout,
    isAdminMode,
    toggleAdminMode,
  } = useAuth();

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setShowNotifications(false);
    }
    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(event.target as Node)
    ) {
      setShowUserMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const headerBackgroundColor = isAdminMode ? "bg-red-900" : "bg-gray-800";

  return (
    <header
      className={`${headerBackgroundColor} text-white p-4 sticky top-0 z-50 w-full`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Link to="/" className="text-xl font-bold">
            GrindOlympiads
          </Link>
          {isAdminMode &&
            viewAsUserProfile !== userProfile &&
            setViewAsUserProfile && (
              <ViewAsDropdown
                viewAsUserProfile={viewAsUserProfile}
                userProfile={userProfile}
                setViewAsUserProfile={setViewAsUserProfile}
              />
            )}
        </div>
        <div className="flex items-center space-x-4">
          {isLabsPath && (
            <Link to="/labs" className="text-white hover:text-gray-300">
              Components Directory
            </Link>
          )}
          <Link to="/blog" className="text-white hover:text-gray-300">
            Blog
          </Link>
          {currentUser ? (
            <>
              <NotificationBell
                ref={notificationRef}
                notifications={notifications}
                notificationsError={notificationsError}
                showNotifications={showNotifications}
                setShowNotifications={setShowNotifications}
                markNotificationAsRead={markNotificationAsRead}
              />
              <UserMenu
                ref={userMenuRef}
                user={currentUser}
                userProfile={userProfile}
                showUserMenu={showUserMenu}
                setShowUserMenu={setShowUserMenu}
                login={login}
                logout={logout}
                isAdminMode={isAdminMode}
                toggleAdminMode={toggleAdminMode}
              />
            </>
          ) : (
            <button
              onClick={login}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Log In
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
