import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import type { ChallengeData, GradedChallenge } from "../types";

const useChallenges = () => {
  const [challenges, setChallenges] = useState<ChallengeData[]>([]);
  const [gradedChallenges, setGradedChallenges] = useState<GradedChallenge[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const functions = getFunctions();
  const { userProfile, viewAsUserProfile } = useAuth();

  // Check if we're viewing as another user
  const isViewingAsOtherUser = useMemo(() => {
    return (
      viewAsUserProfile?.uid &&
      userProfile?.uid &&
      viewAsUserProfile.uid !== userProfile.uid
    );
  }, [viewAsUserProfile?.uid, userProfile?.uid]);

  // Fetch challenges from the backend
  const fetchChallenges = useCallback(async () => {
    try {
      setLoading(true);
      const fetchChallengesFunction = httpsCallable<
        void,
        { challenges: ChallengeData[] }
      >(functions, "fetchChallenges");

      const result = await fetchChallengesFunction();
      setChallenges(result.data.challenges);
      setError(null);
    } catch (err) {
      console.error("Error fetching challenges:", err);
      setError("Failed to load challenges. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [functions]);

  // Fetch graded challenges for a specific user
  const fetchGradedChallengesForUser = useCallback(
    async (userId: string) => {
      try {
        const fetchGradedChallengesFunction = httpsCallable<
          { userId: string },
          { gradedChallenges: GradedChallenge[] }
        >(functions, "fetchGradedChallenges");

        const result = await fetchGradedChallengesFunction({ userId });
        return result.data.gradedChallenges;
      } catch (err) {
        console.error(
          `Error fetching graded challenges for user ${userId}:`,
          err,
        );
        return [];
      }
    },
    [functions],
  );

  const fetchGradedChallenges = useCallback(async () => {
    const targetUserId = viewAsUserProfile?.uid;

    if (targetUserId) {
      const gradedChallengesResult =
        await fetchGradedChallengesForUser(targetUserId);
      setGradedChallenges(gradedChallengesResult);
    } else {
      setGradedChallenges([]);
    }
  }, [viewAsUserProfile?.uid, fetchGradedChallengesForUser]);

  useEffect(() => {
    fetchChallenges();
    fetchGradedChallenges();
  }, [fetchChallenges, fetchGradedChallenges]);

  // Submit challenge responses to the backend (only for current user)
  const submitChallengeResponse = async (
    challengeId: string,
    responses: { [key: string]: { answer: string; timeSpent: number } },
  ) => {
    if (isViewingAsOtherUser) {
      const error =
        "Cannot submit challenge responses while viewing as another user";
      console.error(error);
      throw new Error(error);
    }

    try {
      const submitChallengeResponseFunction = httpsCallable<
        {
          challengeId: string;
          responses: { [key: string]: { answer: string; timeSpent: number } };
        },
        { score: number }
      >(functions, "submitChallengeResponse");

      const result = await submitChallengeResponseFunction({
        challengeId,
        responses,
      });
      await fetchGradedChallenges();

      return result.data;
    } catch (err) {
      console.error("Error submitting challenge response:", err);
      throw err;
    }
  };

  return {
    challenges,
    gradedChallenges,
    loading,
    error,
    isViewingAsOtherUser,
    submitChallengeResponse,
  };
};

export default useChallenges;
