import type React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Last updated: October 6, 2024</p>
      <p className="mb-4">
        GrindOlympiads ("we", "our", or "us") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you use our application.
      </p>
      <h2 className="text-2xl font-bold mb-2">Information We Collect</h2>
      <p className="mb-4">
        We collect information that you provide directly to us when you use our
        application. This may include Google authentication details and your
        interactions with our tests and challenges.
      </p>
      <h2 className="text-2xl font-bold mb-2">How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect to provide you with the best guidance
        on your test-taking and to improve our application's performance and
        user experience.
      </p>
      <h2 className="text-2xl font-bold mb-2">How We Share Your Information</h2>
      <p className="mb-4">
        We do not share your personal information with any third parties. Your
        data is used solely for the purpose of improving your experience with
        GrindOlympiads.
      </p>
      <h2 className="text-2xl font-bold mb-2">Data Security</h2>
      <p className="mb-4">
        We implement appropriate technical and organizational measures to
        protect the security of your personal information. However, please note
        that no method of transmission over the Internet or electronic storage
        is 100% secure.
      </p>
      <h2 className="text-2xl font-bold mb-2">
        Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Last updated" date.
      </p>
      <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us
        via our{" "}
        <Link to="/feedback" className="text-blue-600 hover:underline">
          feedback page
        </Link>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
